.basket-bottom {
  display: none;
}

.basket-bottom-legacy {
  width: 100%;
  float: left;
  color: $grayDarkColor;
  margin: 28px 0 12px;

  @media (min-width: $screen-tablet-portrait) {
    display: flex;
    justify-content: flex-start;
    flex-flow: row;
    margin: 16px 0;
    float: none;
  }

  @media (min-width: $screen-tablet-landscape) {
    [lang="de-CH"] & > :first-child {
      max-width: calc(53% - 170px);
      padding-right: 0;
    }
  }

  .html-block & {
    font-size: 14px;
    line-height: 1.4;
    color: $grayDarkColor;

    h4 {
      color: $grayDarkColor;
      font-size: 18px;
      line-height: 24px;
      font-weight: $fontWeightBold;
      margin: 0 0 8px;
      text-align: left;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.4;
      color: $grayDarkColor;
      margin: 0 0 19px;
      text-align: left;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      strong,
      b {
        margin: 0;
        color: $grayDarkColor;
        font-weight: $fontWeightSemiBold;
      }
    }

    img {
      margin: 0 15px 0 0;
      padding: 0;

      @media (min-width: $screen-tablet-landscape) {
        margin: 0 57px 0 0;
      }
    }
  }

  .info-popup {
    &__info {
      margin: -2px 1px 1px 0;
    }

    p {
      color: $grayDarkColor !important;
      font-size: $infoPopupFontSize;
      line-height: $infoPopupLineHeight;
      margin: 0 0 12px;
    }
  }

  &__left {
    width: 100%;
    margin: 0 0 20px;
    padding: 0 0 20px;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      width: auto;
      margin: 0 40px 0 0;
      padding: 0;
      min-width: 250px;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      height: 1px;
      width: 100%;
      border-radius: 2px;
      background: $sandMediumColor;
      /* stylelint-disable */
      background: linear-gradient(90deg, $sandMediumColor 0%, rgb(47 47 45 / .2) 52.25%, $sandMediumColor 100%);
      /* stylelint-enable */

      @media (min-width: $screen-tablet-portrait) {
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
        height: 100%;
        width: 1px;
        /* stylelint-disable */
        background: linear-gradient(180deg, $sandMediumColor 0%, rgb(47 47 45 / .2) 52.25%, $sandMediumColor 100%);
        /* stylelint-enable */
      }
    }
  }

  &__middle {
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: 70%;
      padding: 0 20px 0 0;
    }

    @media (min-width: $screen-desktop) {
      width: 42%;
    }

    .html-block & {
      p {
        display: inline-block;
        margin: 0 0 6px;

        @media (min-width: $screen-tablet-portrait) {
          margin: 6px 0 12px;
        }
      }
    }

    .footer__sprites {
      vertical-align: top;
      padding: 0;
      white-space: nowrap;
      display: inline-block;

      @media (max-width: $screen-mobile-landscape-max) {
        display: block;
        margin: 8px 0 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        padding: 6px 0 0 3px;
      }

      i {
        margin: 0 -30px 0 0;
        vertical-align: top;
        transform-origin: left top;
        transform: scale(.7);
        display: inline-block;

        &.sprite-post_ch { // stylelint-disable-line
          margin: 0 -10px 0 0;
          transform: scale(.8);
        }
      }
    }
  }

  &__right {
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: auto;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: auto;
      max-width: 480px;
    }

    @media (min-width: $screen-desktop) {
      max-width: none;
    }

    .footer__sprites {
      vertical-align: top;

      @media (max-width: $screen-mobile-landscape-max) {
        display: block;
        margin: 8px 0 0;
      }

      i {
        margin: 0 5px 0 0;
      }
    }
  }

  &__list-item {
    display: block;
    padding: 0 0 11px;

    @media (min-width: $screen-tablet-portrait) {
      display: inline-block;
      float: left;
      padding: 6px 16px 2px 0;
      width: 100%;
    }
  }

  .icon.icon--checkSmall {
    padding: 0 4px;

    &::before {
      font-size: 10px;
    }
  }

  .trustpilot-box {
    background: transparent;
    padding: 0;
    margin: auto;

    &-logo {
      background: url(../staticImages/trustpilot-logo-transparent.png) 0 0 no-repeat;
    }
  }
}
