.checkout-step-address {
  &__box {
    &-new {
      height: 181px;
    }

    &-plus {
      text-align: center;
      display: block;
      margin: 35px auto 0;
      color: $textGrayColor;
      text-decoration: none;

      @include iconFont(plus) {
        background: $orangeColor;
        color: $whiteColor;
        font-size: 25px;
        margin-bottom: 16px;
        text-decoration: none;
        border-radius: 50%;
        padding: 16px;
      }

      &:hover {
        &::before {
          background: $orangeHoverColor;
        }
      }

      &:active {
        &::before {
          background: $orangeActiveColor;
        }
      }
    }

    &-link {
      margin-top: 10px;
      color: $orangeColor;
      font-size: 16px;
      display: block;
    }
  }

  &__sub-headline {
    margin: 8px 0;
    font-size: $fontSizeXMedium;
    font-weight: $fontWeightBold;
    line-height: 120%;
  }

  &__subline {
    font-size: 13px;
    margin: 4px 0 8px;
    display: block;
    line-height: 120%;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 17px;
      margin: 8px 0;
    }
  }

  &__submit-btn {
    @media (min-width: $screen-mobile-landscape) {
      margin-right: 16px;
    }
  }

  &__mandatory-text {
    margin-top: 16px !important;
    display: block;
  }

  .address-form-inputs__input-title {
    max-width: 140px;
  }

  .checkout-step-next-button {
    min-width: 200px;
  }
}
