@import '../../../bps/components/productOrderPosition/productOrderPosition.scss';

.product-order-position {
  $root: &;

  &__cell {
    &--summary {
      @media (min-width: $screen-tablet-portrait) {
        padding-right: 10px !important;
      }
    }

    &--price {
      @media (min-width: $screen-tablet-portrait) {
        min-width: 110px;
      }

      .product-order-position__price {
        align-items: flex-end;

        .price-info {
          @media (min-width: $screen-tablet-portrait) {
            margin-top: 11px;
          }

          &.price-info--reduction {
            @media (min-width: $screen-tablet-portrait) {
              margin-top: 0;
            }

            .price-info__current {
              margin: 1px 0;

              @media (min-width: $screen-tablet-portrait) {
                margin: 3px 0;
              }
            }
          }
        }
      }

      .price-info__unit {
        @media (min-width: $screen-tablet-portrait) {
          min-height: 11px;
        }
      }
    }
  }

  &__price {
    @media (max-width: $screen-mobile-landscape-max) {
      min-height: 38px;
    }
  }

  &--free-item {
    background-color: $productOrderFreeItemBackgroundColorLegacy;

    .product-order-position__cell--info {
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      & > * {
        order: 2;
        margin: 0;
      }
    }

    .product-order-position__promotion {
      order: 1;
      border-radius: 2px;
      margin-bottom: 4px;

      & > * {
        font-weight: $fontWeightRegular;
      }
    }

    .price-info {
      &__savedPercentage {
        display: none !important;
      }
    }

    .counter--disabled {
      background: #0000;

      .counter__input--disabled,
      .counter__input[disabled] {
        border: 0;
        background-color: #0000;
      }
    }
  }

  &__name {
    font-family: $fontHeadlineFamily;
    font-size: 17px;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 20px;
    }
  }

  &__product-nr {
    font-size: 13px;
    color: $brownMediumColor;
  }

  &__availability-info {
    @media (min-width: $screen-tablet-portrait) {
      padding-left: 24px;
      margin: 2px 0 0;
    }

    .availability-info--label {
      text-transform: none;
      color: $textBaseColor;
    }
  }

  &__quantity-input {
    @media (max-width: $screen-mobile-landscape-max) {
      margin: 4px auto 2px;
    }
  }

  .counter__input {
    font-size: 16px;
  }

  .price-info {
    align-items: flex-end;
    font-family: $fontPrimaryFamily;

    &__current,
    &__currency {
      font-size: 16px;
    }

    &__value-cent,
    &__old-value-cent {
      font-size: 1em;
      line-height: inherit;
    }

    &__old-value {
      & > * {
        font-size: 13px;
        color: $brownMediumActiveColor;
      }
    }

    &__info {
      justify-content: flex-end;

      & > * {
        color: $brownMediumActiveColor !important;
      }
    }
  }

  &__remove {
    @media (min-width: $screen-tablet-portrait) {
      padding: 12px 35px 10px 15px;
    }

    &::before {
      font-size: 14px;
    }
  }

  .price-info--reduction {
    .price-info {
      &__current {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      &__savedPercentage {
        position: relative;
        left: auto;
        right: auto;
        display: inherit;
        transform: none;
        order: 1;
        margin: 0 8px 0 0;

        @media (min-width: $screen-tablet-portrait) and (max-width: $screen-tablet-portrait-max) {
          margin: 0 4px 0 0;
          font-size: 10px;
        }
      }

      &__value {
        display: inherit;
        order: 2;
      }

      &__currency {
        display: inherit;
        order: 3;
        padding: 0 0 0 5px;
      }
    }
  }

  &__delivery-modes {
    margin: 0 0 8px;

    .radio-input {
      display: inline-block;
      width: auto;
      margin: 0 16px 8px 0;
    }

    .radio-input__radio {
      height: 18px;
      width: 18px;
      border: 1px solid $brownMediumColor;
      margin-top: 2.5px;
      top: 0;
    }

    .radio-input__label {
      font-weight: $fontWeightRegular;
    }
  }
}
