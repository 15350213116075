.reset-password-page {
  &__wrapper {
    background: transparent;

    @media (min-width: $screen-tablet-portrait) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .form__text-info {
    font-size: $fontSizeSmall;
    margin: 16px 0;
  }

  &__icon {
    margin: 0 20px 0 0;

    @include iconFont(checked, before);

    &::before {
      font-size: 40px;
      color: $brandPrimaryColor;
    }
  }
}
