.product-order-free-item {
  $root: &;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  border-bottom: 0;

  &__wrapper {
    margin: 8px 0;
    padding: 16px 16px 8px;
    background-color: $productOrderFreeItemBackgroundColorLegacy;

    @media (min-width: $screen-desktop) {
      padding: 40px 16px 0;
      min-height: 310px;
    }

    &--bg-img {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &--hide {
      display: none;
    }

    .checkout-step-summary & {
      display: none;
    }

    &--visible {
      display: block !important;
    }

    &--is-abo:not(#{$root}__wrapper--hide) {
      display: block;
    }
  }

  &__headline {
    color: $productOrderFreeItemHeadlineColor;
    font-family: $fontPrimaryFamily;
    font-size: 18px;
    line-height: 22px;
    font-weight: $fontWeightBold;
    text-align: center;
    margin: 8px 8px 0;

    @media (min-width: $screen-tablet-xsm) {
      font-size: 28px;
      line-height: 32px;
      margin: 0 0 8px;
    }

    div {
      @include fontSize($productOrderFreeItemHeadlineSmallSize);
      display: block;
      line-height: 1.3;
      margin: 16px 32px;
      font-weight: $productOrderFreeItemHeadlineSmallWeight;
    }

    a {
      color: $productOrderFreeItemHeadlineColor !important;
    }
  }

  &__product {
    background-color: $whiteColor;
    box-shadow: 0 2px 2px 0 rgb(47 47 45 / .2);
    margin: 16px auto;
    padding: 16px 0;
    max-width: 400px;

    @media (min-width: $screen-tablet-xsm) {
      margin: 12px;
      padding: 24px 0;
      min-height: 160px;
      max-width: 320px;
      min-width: 320px;
    }

    @media (min-width: $screen-tablet-landscape) {
      max-width: 370px;
      min-width: 370px;
    }
  }

  &__container {
    display: block;
    overflow: hidden;

    @media (min-width: $screen-desktop) {
      display: flex;
      min-height: 250px;
    }

    &-center {
      width: 100%;
      z-index: 1;

      @media (min-width: $screen-tablet-xsm) {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        padding: 0 0 12px;
      }

      @media (min-width: $screen-desktop) {
        width: 78%;
        padding: 0 0 12px;
        margin: 0 0 40px;
      }
    }

    &-left,
    &-right {
      display: none;

      @media (min-width: $screen-desktop) {
        width: 16%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        flex-flow: column;
      }

      .illustration {
        transform: rotateY(180deg);
        width: 160px;
        position: relative;
        height: 210px;
      }
    }

    &-left {
      .illustration {
        float: left;
        width: 170px;
        height: 243px;
        top: 14px;
      }
    }

    &-right {
      .illustration {
        float: right;
        width: 160px;
        height: 205px;
        top: 2px;
        right: 13px;
      }
    }
  }

  &__cell {
    @include fontSize($baseFontSize);
    padding: 0;

    &--image {
      width: 33%;
      order: 1;

      @media (min-width: $screen-tablet-landscape) {
        width: 40%;
      }
    }

    &--info {
      width: 66%;
      display: block;
      order: 2;
      flex-grow: 1;
      padding: 0 16px 0 0;

      @media (min-width: $screen-tablet-xsm) {
        padding: 0 24px 0 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        width: 60%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
      }
    }

    &--button {
      margin: 16px 0 0;
    }

    &--disabled {
      color: $grayColor !important;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    max-width: 110px;
    max-height: 110px;
    margin: auto;
    padding: 0 8px;

    @media (min-width: $screen-tablet-landscape) {
      padding: 0;
    }
  }

  &__name {
    color: $productOrderFreeItemNameColor;

    &:hover,
    &:visited {
      color: $productOrderFreeItemNameColor;
    }

    @media (min-width: $screen-tablet-landscape) {
      min-height: 90px;
    }
  }

  &__short-description {
    color: $productOrderFreeItemShortDescriptionColor;
    font-size: 14px;
    font-weight: $fontWeightRegular;
    line-height: 1.4;
    display: block;

    &:hover,
    &:visited {
      color: $productOrderFreeItemShortDescriptionColor;
    }
  }

  &__description3 {
    display: $productOrderFreeItemDescription3Display;
    color: $productOrderFreeItemDescription3Color;
    font-size: 14px;
    font-weight: $fontWeightRegular;
    line-height: 1.2;
    padding: 6px 0 0;

    &:hover,
    &:visited {
      color: $productOrderFreeItemDescription3Color;
    }
  }

  &__add-to-basket-button {
    max-width: none;
    width: auto;
    padding: 0 16px;
    min-width: 164px;
    display: flex;
    white-space: inherit;
    justify-content: center;
    min-height: $buttonPrimaryHeightMobile;

    @media (min-width: $screen-tablet-xsm) {
      min-height: $buttonPrimaryHeightDesktop;
      min-width: 100%;
    }

    &::after,
    > span {
      align-self: center;
      line-height: 1.2;
    }

    &--selected,
    &--selected:hover {
      color: $productOrderFreeItemButtonSelectedColor;
      background-color: $productOrderFreeItemButtonSelectedBackgroundColor;
      border: 0 none;
      line-height: $buttonPrimaryHeightMobile;

      @media (min-width: $screen-tablet-xsm) {
        line-height: $buttonPrimaryHeightDesktop;
      }

      @include iconFont(checkSmall, after) {
        color: $productOrderFreeItemButtonSelectedColor;
        font-size: 9px;
        padding: 0 0 0 16px;
        line-height: inherit;
        transform: none;
      }
    }
  }
}

.cart-page__order-summary > .product-order-free-item__hide-slot {
  display: none;
}
