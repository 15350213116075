.checkout-step-confirmation {
  &__content {
    @include container($maxWidthInner);
    padding-bottom: 16px;

    @media (min-width: $screen-tablet-portrait) {
      @include container();
      padding-bottom: 0;
    }

    &--no-padding-top {
      padding-top: 0;
    }

    &--wide {
      width: 100%;
    }
  }

  &__footer {
    padding-top: 0;
  }

  &__header {
    @include container($maxWidthInner);
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    margin: 16px 0;

    @media (min-width: $screen-tablet-portrait) {
      @include container();
      margin: 40px auto;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }

  &__headline {
    font-size: 24px;
    margin: 0;
    padding: 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 34px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 45px;
    }
  }

  &__content-row {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $screen-mobile-landscape) {
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
    }

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 40px;
    }

    &--lg {
      gap: 16px;

      @media (min-width: $screen-mobile-landscape) {
        flex-direction: column;
      }

      @media (min-width: $screen-tablet-portrait) {
        flex-direction: row;
        gap: 32px;
      }
    }
  }

  &__content-image {
    .lazy-load-image {
      position: relative;
      height: 100%;
    }

    @media (min-width: $screen-mobile-landscape) {
      width: auto;
      display: flex;
      justify-content: flex-start;
    }

    img {
      float: left;
      width: 100%;
    }
  }

  &__content-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 16px 16px;
    padding: 16px;
    width: 100%;
    align-items: center;
    background: $sandHoverColor;

    @media (min-width: $screen-mobile-landscape) {
      min-width: 250px;
      width: 38%;
      margin: 0;
      flex-grow: 1;
      padding: 24px 24px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    @media (min-width: $screen-tablet-landscape) {
      min-width: 350px;
    }
  }

  &__content-column {
    background: $sandHoverColor;
    display: flex;
    padding: 14px 16px 16px;
    width: 100%;
    margin: 0;
    font-size: 12px;
    line-height: 1.4;
    flex-flow: wrap row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 3.6px;

    @media (min-width: $screen-tablet-landscape) {
      width: 50%;
      gap: 28px;
      font-size: 14px;
      padding: 24px 28px 28px;
    }

    &.sovendus {
      justify-content: center;

      > * {
        width: 100%;
      }
    }

    &-right {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      min-height: 100px;
      width: 70%;
    }

    &-icon {
      padding: 8px 16px 8px 0;

      @media (min-width: $screen-tablet-portrait) {
        padding: 16px 16px 16px 0;
      }

      @media (min-width: $screen-desktop) {
        padding: 16px;
      }

      .icon {
        &::before {
          font-size: 40px;
          color: $goldColor;

          @media (min-width: $screen-tablet-portrait) {
            font-size: 50px;
          }
        }
      }
    }

    &-headline {
      font-size: 21px;
      line-height: 1.4;
      color: $textDarkestColor;
      font-weight: $fontWeightBold;
      font-family: $fontHeadlineFamily;
      text-align: left;
      margin: 0 0 18px;

      @media (min-width: $screen-tablet-landscape) {
        font-size: 32px;
      }
    }

    &-text {
      margin: 0 0 24px;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 16px;
        line-height: 1.5;
      }
    }

    &-button--desktop {
      display: none;

      @media (min-width: $screen-tablet-portrait) {
        display: block;
      }
    }

    &-button--mobile {
      flex-grow: 1;
      align-self: flex-end;

      @media (min-width: $screen-mobile-landscape) {
        padding: 0 0 0 35%;
      }

      @media (min-width: $screen-tablet-portrait) {
        display: none;
      }

      .button {
        width: 100%;

        @media (min-width: $screen-mobile-landscape) {
          width: auto;
        }
      }
    }

    .button--secondary {
      @include switchButtonStyle('secondary>primary');
    }
  }

  &__content-headline {
    font-size: 21px;
    line-height: 1.6;
    color: $textDarkestColor;
    font-weight: $fontWeightBold;
    font-family: $fontHeadlineFamily;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 32px;
    }
  }

  &__content-text {
    font-size: 13px;
    line-height: 1;
    color: $orangeColor;
    margin: 0 0 8px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 17px;
      margin: 0 0 16px;
    }
  }

  &__illustration {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      flex-direction: column;
      background: url(../staticImages/danke-hand.svg) no-repeat center;
      background-size: contain;
    }
  }

  &__icon {
    display: block;
    float: left;
    font-size: 40px;
    margin: 0 25px 0 5px;

    &--delivery {
      @include iconFont(shipping);
    }
  }

  &__teaser {
    background: $sandHoverColor;
    display: flex;
    padding: 24px 28px 28px;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    align-self: stretch;
    border-radius: 3.6px;

    &-headline {
      font-size: 21px;
      line-height: 1.4;
      color: $textDarkestColor;
      font-weight: $fontWeightBold;
      font-family: $fontHeadlineFamily;
      text-align: center;

      @media (min-width: $screen-tablet-landscape) {
        font-size: 32px;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      @media (min-width: $screen-tablet-portrait) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &-block {
      padding: 0 24px;
      font-size: 12px;
      line-height: 1.4;
      max-width: 280px;

      @media (min-width: $screen-tablet-portrait) {
        flex-grow: 1;
        width: 15%;
        padding: 0;
        align-self: flex-start;
        max-width: none;
      }

      @media (min-width: $screen-tablet-landscape) {
        font-size: 13px;
      }

      &--bigger {
        @media (min-width: $screen-tablet-portrait) {
          width: 20%;
        }
      }
    }

    &-icon {
      padding: 8px 0;
      font-size: 14px;
      line-height: 1.4;
      font-weight: bold;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0;
        display: flex;
        font-size: 14px;
        line-height: 1.4;
        font-weight: bold;
        height: 76px;
        justify-content: center;
        flex-flow: column;
      }

      &::before {
        font-size: 40px;
        color: $goldColor;
      }

      &.icon--confirmationMail {
        &::before {
          font-size: 40px;
        }
      }

      &.icon--confirmationBox {
        &::before {
          font-size: 60px;
        }
      }

      &.icon--confirmationVersand {
        &::before {
          font-size: 38px;
        }
      }

      &.icon--confirmationTracking {
        &::before {
          font-size: 60px;
        }
      }

      &.icon--confirmationHome {
        &::before {
          font-size: 60px;
        }
      }
    }

    &-divider {
      padding: 24px 0 16px;
      display: block;

      @include iconFont(confirmationArrow) {
        font-size: 14px;
        padding: 8px 0;
        color: $textDarkestColor;
        transform: rotate(90deg);

        @media (min-width: $screen-tablet-portrait) {
          transform: none;
        }

        @media (min-width: $screen-tablet-landscape) {
          padding: 8px;
        }
      }

      @media (min-width: $screen-tablet-portrait) {
        align-self: flex-start;
        margin: 24px 0 0;
      }
    }
  }
}
