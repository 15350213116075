.creditcard {
  &__infotext {
    display: none;
  }

  &__cvc-field {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > .input {
      flex-grow: 1;
    }
  }

  &__expire-inputs {
    min-width: 180px;

    .form__col-5,
    .form__col-7 {
      @media (max-width: $screen-mobile-landscape-max) {
        width: 50%;
      }
    }

    .select .form__group--row {
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  &__error-output {
    padding: 0;
    margin: 0;
  }

  .info-popup__wrapper {
    margin-left: 8px;
  }
}
