.header-checkout {
  position: relative;
  z-index: $indexHeader;

  &__main {
    background: var(--header-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    flex-wrap: wrap;
    position: relative;
    min-height: 46px;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      @include container($maxWidthHeader);
    }

    @media (min-width: $screen-desktop-sm) {
      min-height: 95px;
      padding: 10px 40px;
      align-items: flex-start;
    }
  }

  &__wrapper {
    background: var(--header-background);
    order: 1;
  }

  &__trust-wrapper {
    display: none;
    order: 2;

    @media (min-width: $screen-laptop) {
      display: block;
    }
  }

  &__logo-wrapper {
    display: block;
    height: auto;
    text-align: center;
  }

  &__logo-link {
    display: flex;
    z-index: 600;
    height: 40px;
    width: 73px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 6px;
    overflow: hidden;

    @media (min-width: $screen-desktop-sm) {
      width: auto;
      height: auto;
      position: relative;
      top: 0;
    }
  }

  &__logo {
    display: block;
    width: 80px;
    height: 44px;

    @media (min-width: $screen-desktop-sm) {
      width: 135px;
      height: 75px;
    }
  }

  &__logo-claim {
    display: none;

    @media (min-width: $screen-desktop-sm) {
      display: block;
      width: 194px;
      height: 75px;
      margin: 0 0 0 36px;
    }
  }

  .user-shortcut__link {
    color: $whiteColor;
  }

  .header__user-shortcuts {
    @media (min-width: $screen-desktop-sm) {
      margin: auto 0;
      flex-grow: 0;
    }
  }

  .header__user-shortcut--cart {
    display: none;
  }
}
