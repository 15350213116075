.voucher-form {
  display: flex;
  align-items: flex-end;
  flex-flow: column;
  margin: 0;

  @media (min-width: $screen-tablet-landscape) {
    align-items: flex-start;
    flex-flow: row;
  }

  &__group {
    display: flex;
    align-items: flex-start;
    margin: 0;
  }

  &__form {
    padding: 0;
    order: 2;
    width: 100%;

    @media (min-width: $screen-tablet-landscape) {
      order: 1;
      width: 53%;
    }
  }

  &__code {
    padding: 0 0 8px;
    order: 1;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: calc(50% + 8px);
    }

    @media (min-width: $screen-tablet-landscape) {
      order: 2;
      width: 47%;
      padding: 16px 0 0;
    }
  }

  &__info {
    font-size: 10px;
    margin: 0 0 8px;
    line-height: 1.2;
    color: $voucherFormInfoColor;
    font-weight: $voucherFormInfoFontWeight;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 10px;
      font-size: 14px;
    }

    .info-popup__wrapper {
      padding: 0 4px 5px 0;
      float: left;
    }

    .info-popup {
      font-weight: $fontWeightRegular;
    }
  }

  &__input {
    flex-grow: 1;

    .input__label--as-placeholder {
      position: absolute;
    }
  }

  &__submit-button {
    margin-left: 10px;
    min-width: 170px;
    width: auto;
  }

  &__encashed-row {
    padding: 0 0 16px 8px;
    position: relative;
    font-size: 14px;
    color: $grayDarkColor;
    font-weight: $voucherFormFontWeightBold;
    display: flex;
    align-items: flex-start;
    margin: 0;

    @media (min-width: $screen-tablet-landscape) {
      padding: 10px 0;
      font-size: 18px;
    }

    &--error {
      display: block;
      padding: 0;
    }
  }

  &__encashed-text {
    padding: 0 10px 0 0;
    width: 45%;
    text-align: left;

    @media (min-width: $screen-tablet-landscape) {
      width: 53%;
      text-align: right;
    }
  }

  &__encashed-code {
    width: 43%;
    text-align: right;

    @media (min-width: $screen-tablet-landscape) {
      width: 30.5%;
      text-align: right;
    }
  }

  &__encashed-error {
    width: 100%;
    display: block;
    text-align: left;
    color: $errorColor;
    font-size: $fontSizeSmall;
    font-weight: $fontWeightRegular;
    padding: 0 24px 8px 8px;

    @media (min-width: $screen-tablet-landscape) {
      padding: 0 0 8px 24px;
      text-align: right;
      width: 83.5%;
    }

    @media (min-width: $screen-laptop) {
      padding: 0 0 8px 120px;
    }
  }

  &__remove {
    color: $removeColor;
    text-decoration: none;
    padding: 2px 8px;
    position: absolute;
    right: 0;
    top: -2px;
    cursor: pointer;
    width: 12%;
    text-align: center;

    @media (min-width: $screen-tablet-landscape) {
      padding: 10px;
      width: 13%;
    }

    &:hover {
      text-decoration: none;

      @media (min-width: $screen-desktop-sm) {
        color: $removeHoverColor;
      }
    }

    @include iconFont(delete) {
      font-size: 8px;
      position: absolute;
      right: 10px;
      top: 6px;

      @media (min-width: $screen-tablet-portrait) {
        right: 16px;
      }

      @media (min-width: $screen-tablet-landscape) {
        top: 19px;
      }

      @media (min-width: $screen-desktop) {
        top: 19px;
        left: 0;
        right: 0;
      }
    }
  }

  .input__wrapper {
    background: $voucherFormInputBackground;
  }

  &__placeholder {
    @include componentPreloader() {
      height: auto;
    }
  }
}
