.checkout-step-summary {
  &__headline {
    font-size: $checkoutStepSummaryHeadlineFontSizeMobile;
    margin: $checkoutStepSummaryHeadlineMarginMobile;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $checkoutStepSummaryHeadlineFontSizeDesktop;
      margin: $checkoutStepSummaryHeadlineMarginDesktop;
    }
  }

  &__checkout-box {
    @include container($maxWidthInner);
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    margin: 16px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      @include container();
      margin: 40px auto 16px;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: row wrap;
    }

    @media (min-width: $screen-tablet-landscape) {
      margin: 40px auto 24px;
    }

    .checkout__box {
      width: 100%;
      margin: 0 0 16px;
      border-bottom: 1px solid $grayLightColor;
      padding: 0 0 16px;

      @media (min-width: $screen-tablet-portrait) {
        border-bottom: 0 none;
        padding-bottom: 0;
      }

      &--invoice {
        @media (min-width: $screen-tablet-portrait) {
          width: 50%;
        }

        @media (min-width: $screen-tablet-landscape) {
          width: 31%;
        }
      }

      &--delivery {
        @media (min-width: $screen-tablet-portrait) {
          width: 50%;
        }

        @media (min-width: $screen-tablet-landscape) {
          width: 32%;
        }
      }

      &--payment {
        @media (min-width: $screen-tablet-portrait) {
          width: 100%;
          padding: 16px 0 0;
          margin: 0;
          border-top: 1px solid $grayLightColor;
        }

        @media (min-width: $screen-tablet-landscape) {
          width: 37%;
          padding: 0;
          margin: 0;
          border-top: 0 none;
        }
      }
    }

    .checkout__box-header {
      justify-content: space-between;
      font-size: $checkoutStepSummaryBoxHeaderFontSizeMobile;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $checkoutStepSummaryBoxHeaderFontSizeDesktop;
        justify-content: flex-start;
      }
    }

    .checkout__box-content {
      font-size: $checkoutStepSummaryBoxContentFontSizeMobile;
      line-height: 1.5;

      @media (min-width: $screen-tablet-landscape) {
        font-size: $checkoutStepSummaryBoxContentFontSizeDesktop;
      }
    }

    .checkout__box-button {
      margin-left: 24px;
    }

    .checkout__box-icon {
      > span {
        position: relative;
      }
    }

    .checkout__box-content--list {
      display: flex;
      justify-content: stretch;
      flex-direction: row;
      margin: 0 0 8px;

      &:last-child {
        margin: 0;
      }

      .checkout__box-text {
        font-weight: $checkoutStepSummaryBoxTextFontWeight;
        padding: 0 0 8px 16px;
        align-self: center;
        font-size: $checkoutStepSummaryBoxTextFontSizeMobile;

        @media (min-width: $screen-desktop) {
          font-size: $checkoutStepSummaryBoxTextFontSizeDesktop;
        }
      }

      .delivery-forecast-message {
        width: 100%;
        font-size: $checkoutStepSummaryBoxTextFontSizeMobile;

        @media (min-width: $screen-desktop) {
          font-size: $checkoutStepSummaryBoxTextFontSizeDesktop;
        }

        &__extra-shipping {
          font-weight: $checkoutStepSummaryBoxTextFontWeight;
        }

        &__prefix {
          font-weight: $checkoutStepSummaryBoxTextFontWeight;
        }
      }
    }
  }

  &__message:not(:empty) {
    @include container($maxWidthInner);
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 5px;

    @media (min-width: $screen-mobile-large) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: $screen-tablet-portrait) {
      @include container();
      padding-left: 24px;
      padding-right: 24px;
    }

    .general-message {
      margin-bottom: 8px;
    }
  }

  &__headline-basket {
    display: block;
    width: 100%;
    padding: 0 10px;
    font-family: $fontPrimaryFamily;
    font-size: 14px;
    line-height: 18px;
    font-weight: $fontWeightBold;

    @media (min-width: $screen-mobile-large) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__number-of-deliveries-text {
    display: none;
  }

  .address-box {
    @media (min-width: $screen-tablet-portrait) {
      padding: 0 16px 0 0;
    }
  }

  .products-order-table__product {
    &:first-child {
      padding: 0 0 14px;

      @media (min-width: $screen-tablet-portrait) {
        padding: 16px 0 14px;
      }
    }
  }

  &__products-order-table {
    width: 100%;

    @media (min-width: $screen-tablet-landscape) {
      margin-top: 24px;
    }

    .products-order-table__header {
      display: none;

      @media (min-width: $screen-tablet-portrait) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $borderDefaultColor;
        border-bottom: 1px solid $borderDefaultColor;
        margin: 16px 0 0;
        padding: 8px 0 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin: 40px 0 0;
      }
    }
  }

  &__button {
    border-bottom: 1px solid $borderDefaultColor;

    &__container {
      @include container($maxWidthOuter);
    }

    .button {
      margin: 0 0 -3px 8px;
      color: $checkoutStepSummaryButtonColor;
      font-size: $checkoutStepSummaryButtonFontSize;
      font-weight: $checkoutStepSummaryButtonFontWeight;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 -3px;
      }
    }
  }

  &__newsletter {
    display: block;
    width: 100%;
    padding: 16px 10px;

    @media (min-width: $screen-mobile-large) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
      padding: 16px 0;
      border-bottom: 1px solid $borderDefaultColor;
    }

    &__label {
      max-width: $checkoutStepSummaryCheckboxMaxWidth;
      padding: $checkoutStepSummaryCheckboxPadding;
      display: flex;

      .checkbox__box {
        left: -2px;
        top: 0;
        transform: none;
      }

      .checkbox__label {
        font-size: $checkoutStepSummaryCheckboxLabelFontSizeMobile;
        line-height: $checkoutStepSummaryCheckboxLabelLineHeight;
        color: $checkoutStepSummaryCheckboxLabelColor;

        @media (min-width: $screen-tablet-portrait) {
          font-size: $checkoutStepSummaryCheckboxLabelFontSizeDesktop;
        }
      }

      a {
        color: $checkoutStepSummaryTextLinkColor;
      }
    }

    &__declaration {
      font-size: $checkoutStepSummaryCheckboxDeclarationFontSize;
      line-height: 1.4;
      color: $checkoutStepSummaryCheckboxLabelColor;
      padding: 8px 0 0 32px;
      max-width: 520px;

      a {
        color: $checkoutStepSummaryTextLinkColor;
      }
    }

    .checkbox--switch + .checkout-step-summary__newsletter__declaration { // stylelint-disable-line
      padding: $checkoutStepSummarySwitchPadding;
    }
  }

  .cart-page__footer__container { // stylelint-disable-line
    display: flex;
    flex-flow: column;

    .checkout-step-summary__newsletter {
      order: 3;

      @media (min-width: $screen-tablet-portrait) {
        order: 1;
      }
    }

    .cart-page__order-summary {
      order: 1;

      @media (min-width: $screen-tablet-portrait) {
        order: 2;
      }

      .order-summary__shipping-info--not-free {
        display: $checkoutStepSummaryShippingInfoDisplay;
      }
    }

    &__order-voucher-section {
      order: 2;
      border-bottom: 2px solid $borderDefaultColor;

      @media (min-width: $screen-tablet-portrait) {
        order: 3;
      }
    }
  }

  &__footer-button {
    order: 3;
    width: 100%;

    &__wrapper {
      background-color: transparent;
    }

    &__container {
      @include container($maxWidthInner);
      margin: 24px auto;

      @media (min-width: $screen-tablet-portrait) {
        @include container();
        margin: 24px auto 72px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        flex-flow: column wrap;
      }
    }
  }

  &__footer-button-col {
    width: 100%;
    order: 1;
    margin: 0;
    padding: 24px 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      width: auto;
      order: 2;
      padding: 24px 0 40px;
      align-self: flex-end;
    }

    > .button {
      margin: 0 0 15px;
      vertical-align: bottom;
      width: 100%;
      max-width: none;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 0 15px;
        width: auto;
        float: right;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__footer__text {
    width: 100%;
    color: $checkoutStepSummaryFooterTextColor;
    font-size: $checkoutStepSummaryFooterTextFontSize;
    line-height: 1.3;

    a {
      color: $checkoutStepSummaryTextLinkColor;
      text-decoration: underline;
      font-size: $checkoutStepSummaryFooterTextFontSize;
      line-height: 1.3;
    }

    p {
      margin: 0 0 8px;

      &:last-child {
        margin: 0;
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      align-self: flex-end;
      max-width: 560px;
      text-align: right;
    }
  }

  &__order-voucher-section {
    order: 3;
    padding: 8px 0 0;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: $screen-tablet-landscape) {
      padding: 0;
    }
  }

  &__details {
    display: block;
    margin-bottom: 30px;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: stretch;
      margin: 0 -20px 20px;
    }

    @media (min-width: $screen-desktop-sm) {
      margin: 0 -30px 20px;
    }
  }

  &__detail-column {
    display: block;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
      padding: 0 20px;
      border-right: 1px solid $borderDefaultColor;
      margin-bottom: 40px;

      &:nth-child(2n) {
        border-right: none;
      }
    }

    @media (min-width: $screen-desktop-sm) {
      padding: 0 30px;
    }
  }

  &__detail-name {
    font-size: $fontSizeMedium;
    font-weight: $fontWeightMedium;
    color: $textBaseColor;
    display: block;
    margin-bottom: 10px;
  }

  &__detail-chosen-option {
    font-size: $baseFontSize;
    color: $textGrayColor;
  }

  &__edit-detail-button,
  &__add-new-button {
    color: $textBaseColor;
    margin-left: 8px;
    text-decoration: none;
    font-size: 14px;

    &:visited {
      text-decoration: none;
      color: $textBaseColor;
    }

    @media (min-width: $screen-desktop-sm) {
      &:hover {
        color: $brandPrimaryColor;
      }
    }

    @include iconFont(edit);
  }

  &__products-order-headline {
    font-size: $fontSizeXXMedium;
    font-weight: $fontWeightMedium;
    color: $textBaseColor;
  }

  &__order-summary-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__order-summary {
    order: 1;
    width: 100vw;
    margin: 0 -15px 20px;
    padding: 15px;
    background: $backgroundGrayColor;

    @media (min-width: $screen-tablet-portrait) {
      order: 2;
      width: 50%;
      margin: 0 0 20px;
    }

    @media (min-width: $screen-desktop-sm) {
      width: 40%;
      padding: 20px;
    }
  }

  .sidebar__wrapper {
    @media (max-width: $screen-mobile-landscape-max) {
      display: flex;
      flex-wrap: wrap;

      .sidebar__wrapper-left {
        order: 2;
      }

      .sidebar__wrapper-right {
        order: 1;
      }

      .sidebar__summary-area {
        padding: 0;
      }
    }
  }

  .product-order-position__delivery-modes {
    margin-top: 8px;

    .radio-input {
      display: none;

      &.radio-input--selected {
        display: block;
      }
    }

    .radio-input__label {
      opacity: 1;
      padding-left: 0;
    }

    .radio-input__radio,
    .radio-input__info {
      display: none;
    }
  }

  .apple-pay-button {
    width: 100%;
    height: 44px;

    @media (min-width: $screen-tablet-portrait) {
      width: 220px;
    }
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
checkout-step-summary-paypal-express {
  @include componentPreloader();
  @include container($maxWidthInner);
  display: block;
  justify-content: space-between;
  width: 100%;
  min-height: 200px;
  margin-bottom: 16px;

  @media (min-width: $screen-tablet-portrait) {
    @include container();
  }

  .checkout__box-icon > span.checkout__box-sprite--PAY_PAL_EXPRESS {
    display: none;
  }
}
