.cart-page {
  &__container {
    @include checkoutLayout();
  }

  &--list-background {
    background-color: transparent;
  }

  &__general-message {
    @include container();
    margin: 16px auto 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 40px auto 0;
    }

    &:empty {
      margin: 0;
    }

    .general-message {
      margin: 0;
    }
  }

  &__full-row {
    @include container($maxWidthInner);
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $screen-mobile-large) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: $screen-tablet-portrait) {
      @include container();
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__header {
    @include container($maxWidthInner);
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 0 20px;

    @media (min-width: $screen-tablet-portrait) {
      @include container();
      margin: 24px auto;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    @media (min-width: $screen-tablet-landscape) {
      margin: 32px auto;
    }

    > .button {
      margin: 0 0 15px;
      vertical-align: bottom;
      width: 100%;
      max-width: none;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0;
        width: auto;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__headline-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 16px 0;

    @media (min-width: $screen-tablet-portrait) {
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin: 0;
    }
  }

  &__header-text {
    @include container($maxWidthInner);
    display: flex;
    flex-direction: column;
    margin: 16px 0;

    @media (min-width: $screen-tablet-portrait) {
      @include container();
      margin: 0 auto 16px;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    &--delivery-promise {
      color: $whiteColor;
      background: $successColor;
      font-size: 13px;
      line-height: 1.4;
      font-weight: $fontWeightRegular;
      padding: 8px 20px;
      border-radius: 4px;
      text-align: center;
      width: 100%;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 17px;
      }
    }
  }

  &__empty-content,
  &__content {
    @include container($maxWidthInner);

    @media (min-width: $screen-tablet-portrait) {
      @include container();
    }
  }

  &__headline {
    font-size: 28px;
    width: auto;
    margin: 0;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 40px;
    }
  }

  &__anker-order-summary {
    position: absolute;
  }

  &--show-mobile {
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      display: none !important;
    }
  }

  &__footer {
    &__container {
      @media (min-width: $screen-tablet-portrait) {
        @include container();
      }
    }
  }

  &__footer-button {
    order: 3;
    width: 100%;

    &__wrapper {
      background-color: transparent;
    }

    &__container {
      @include container($maxWidthInner);
      margin: 24px auto 38px;

      @media (min-width: $screen-tablet-portrait) {
        @include container();
        margin: 16px auto 38px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        flex-flow: column wrap;
      }
    }
  }

  &__footer-button-col {
    width: 100%;
    order: 1;
    margin-top: 20px;

    @media (min-width: $screen-tablet-portrait) {
      width: auto;
      order: 2;
      margin-top: 0;
      align-self: flex-end;
      min-width: 460px;
    }

    > .button {
      margin: 0 0 15px;
      vertical-align: bottom;
      width: 100%;
      max-width: none;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 0 15px;
        width: auto;
        float: right;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__order-summary {
    order: 1;
    width: 100%;
    padding: 16px 0;
    background-color: $whiteColor;
    max-width: $maxWidthOuter;
    margin: 0 auto;

    @media (min-width: $screen-tablet-portrait) {
      order: 2;
      float: left;
      padding: 20px 0;
    }

    .checkout-step-delivery & {
      border-top: 0 none;
      border-bottom: 0 none;
      padding: 5px 0 0;

      @media (min-width: $screen-desktop-sm) {
        padding: 24px 0 0;
      }
    }
  }

  &__order-voucher-section {
    order: 3;
    padding: 0 20px 20px;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    width: 100%;
    background: $whiteColor;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 28px 24px;
      border-radius: 0 0 4px 4px;
    }
  }

  &__info-teaser {
    order: 2;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      order: 1;
      width: 50%;
    }
  }

  &__voucher {
    width: 100%;
    padding: 0 0 16px;

    .cart-page__footer & {
      width: 60%;
      max-width: 460px;
      padding: 0;
      align-self: flex-end;

      @media (min-width: $screen-tablet-landscape) {
        width: 50%;
      }

      .voucher-form__info {
        margin-top: 16px;
      }
    }
  }

  &__paypal-text {
    padding: 10px;
    text-align: center;
  }

  &__grid {
    margin: 0;
  }

  &__submit-button {
    min-width: 270px;
    width: auto;

    i {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px 5px 0;
    }
  }

  &__back-to-home-button {
    @include switchButtonStyle('secondary>primary');
    margin-bottom: 24px;
  }

  &__empty {
    @include fontSize($fontSizeXXMedium);
    color: $textDarkestColor;
    font-weight: $fontWeightBold;
    margin: 24px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 40px 0 24px;
    }
  }

  &__empty-basket-container {
    display: none;
  }

  &__empty-content {
    display: none;
  }

  &--empty {
    .cart-page__footer__wrapper { // stylelint-disable-line selector-class-pattern
      display: none;
    }

    .cart-page__empty-basket-container,
    .cart-page__empty-content {
      display: block;
    }

    .button--paypal-checkout,
    .button--apple-pay-checkout,
    .cart-page__submit-button,
    .cart-page__order-voucher-section,
    .order-summary,
    .basket-informative-benefits,
    .products-order-table__wrapper {
      display: none;
    }
  }
}
