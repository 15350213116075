.products-order-table {
  $root: &;
  $borderStyle: 1px solid rgb(146 117 101 / .2);
  background: $whiteColor;

  @media (min-width: $screen-tablet-portrait) {
    border-radius: 4px 4px 0 0;
  }

  &__wrapper-inner {
    max-width: $maxWidthOuter;
    margin: 0 auto;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 20px;
    }

    @media (min-width: $screen-desktop-sm) {
      padding: 0 40px;
    }
  }

  &__header {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: $borderStyle;
      padding: 16px 0;

      .user-area & {
        padding: 15px 0 0;
        border-bottom: $borderStyle;
      }
    }
  }

  &__header-label {
    font-size: 14px;
    color: $brownMediumActiveColor;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 17px;
    }

    &--image {
      @extend .product-order-position__cell--image;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 20px !important;
      }
    }

    &--info {
      @extend .product-order-position__cell--info;
    }

    &--availability {
      @extend .product-order-position__cell--availability;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    &--price {
      @extend .product-order-position__cell--price;
    }

    &--quantity {
      @extend .product-order-position__cell--quantity;
    }

    &--remove {
      @extend .product-order-position__cell--remove;
    }

    &--summary {
      @extend .product-order-position__cell--summary;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &--reorder {
      @extend .product-order-position__cell--reorder;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__placeholder {
    @include componentPreloader() {
      height: auto;
    }
    min-height: 125px;
  }

  &__cell-label {
    padding: 3px 0 6px;
    width: 100% !important;
    font-size: 13px !important;
    line-height: 14px;
    display: inline-block;
    color: $brownMediumActiveColor;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__product {
    padding: 16px 12px 14px 0;
    border-bottom: $borderStyle;

    @media (min-width: $screen-tablet-portrait) {
      padding: 15px 0;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0 none;
    }
  }

  &__forecast {
    margin: 12px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }
  }

  &__group {
    margin: 0 0 24px;

    &:last-child {
      margin: 0;

      #{$root}__product {
        &:last-child {
          border-bottom: $borderStyle;
        }
      }
    }
  }

  &__groups-order-headline {
    @include fontSize($fontSizeMedium);
    font-weight: $fontWeightSemiBold;
    color: $textBaseColor;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: $grayLighterColor;
    padding: 16px;
    border-bottom: 1px solid $grayLightColor;

    + .products-order-table__header {
      margin-top: 20px;
    }
  }

  &__group-headline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background: $grayLighterColor;
    padding: 16px;
  }

  &__group-number,
  &__group-text {
    @include fontSize($fontSizeMedium);
    font-weight: $fontWeightSemiBold;
    color: $textBaseColor;
    margin: 0 12px 0 0;
  }

  &__group-label {
    @include fontSize($fontSizeSmall);
    color: $whiteColor;
    background: $brandPrimaryColor;
    padding: 4px 16px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: $fontWeightBold;
    margin-top: 16px;
  }

  &__split-message {
    margin: 16px 8px 0 0;
    background: $productsOrderTableSplitMessageBackground;
    padding: 8px;
    max-width: 600px;

    .checkout-step-summary & {
      display: none;
    }
  }

  .counter {
    &__input {
      background: $whiteColor;
      border-color: $grayLightColor;
    }
  }

  .product-order-table-group-head {
    display: none;
  }
}
