.delivery-option-form {
  font-size: 12px;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 14px;
  }

  &__price {
    display: $deliveryOptionFormPriceDisplay;
  }

  .radio-input {
    display: flex;
    flex-flow: row wrap;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .radio-input + .form__radio-sub-element {
    padding-top: 0;
  }

  .radio-input__label {
    padding-left: 32px;
    padding-right: 8px;
    font-weight: $deliveryOptionFormLabelFontWeight;

    @media (min-width: $screen-tablet-portrait) {
      padding-left: 32px;
      max-width: calc(100% - 27px);
    }
  }

  .radio-input__sprite-text-sub {
    @media (max-width: $screen-tablet-portrait) {
      display: block;
    }
  }

  .radio-input__info {
    vertical-align: top;
    top: 5px;

    @media (min-width: $screen-tablet-portrait) {
      top: 4px;
    }
  }

  .radio-input__description {
    font-size: 14px;
    padding-left: 32px;
    width: 100%;
  }

  .radio-input__input:checked ~ .radio-input__label {
    color: $deliveryOptionFormLabelCheckedColor;
  }

  &__text-paragraph {
    font-size: $fontSizeSmall;
    position: relative;
    bottom: 17px;
    left: 25px;

    @media (min-width: $screen-mobile-landscape-max) {
      left: 33px;
    }
  }

  .form__radio-sub-element {
    max-width: 290px;
    display: flex;

    @media (max-width: $screen-mobile-landscape-max) {
      margin-left: 32px;
    }

    .delivery-option-form__price {
      display: block;
      order: 2;
      position: relative;
      top: 14px;
      left: 8px;
      font-weight: $deliveryOptionFormLabelFontWeight;
    }

    .date-input,
    .select {
      flex-grow: 2;
    }
  }

  &__forecast-wrapper {
    margin-bottom: 16px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 24px;
    }
  }

  &__forecast {
    .delivery-forecast-message-list__item {
      margin-bottom: 8px;
    }

    .delivery-forecast-message__icon {
      float: none;

      @media (min-width: $screen-tablet-portrait) {
        float: left;
        display: inline-block;
      }
    }

    .delivery-forecast-message__text {
      display: block;
      font-size: $deliveryForecastMessageFontSizeMobile;

      @media (min-width: $screen-tablet-portrait) {
        display: inline;
        font-size: $deliveryForecastMessageFontSizeDesktop;
      }
    }
  }
}
