.basket-informative-benefits {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: $formHeadlineColor;
  text-decoration: none;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin: 10px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 20px;
    }

    &-image,
    &-text {
      margin: 0;
    }

    img {
      max-height: 54px;
    }
  }
}
