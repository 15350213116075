.checkout-step-next-button {
  @media (min-width: $screen-mobile-landscape) {
    min-width: auto;
    max-width: 300px;
    width: 100%;
  }

  @media (min-width: $screen-tablet-portrait) {
    max-width: none;
    width: auto;
  }

  &--buy-now {
    max-width: none;

    @media (min-width: $screen-laptop) {
      min-width: 227px;
    }
  }

  &.button--secondary {
    margin: 10px 0 0;
  }
}
