.checkout-steps {
  $borderBottomWidth: 2px;
  $root: &;
  position: relative;
  margin: 16px 10px;
  font-size: 13px;

  @media (min-width: $screen-tablet-portrait) {
    font-size: 18px;
    margin: 32px;
  }

  &__track {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    border-bottom: $borderBottomWidth transparentize($sandLighterColor, .5) solid;
    max-width: 640px;
    margin: auto;
  }

  &__step {
    position: relative;
    text-align: center;
    cursor: default;
    text-decoration: none;
    top: $borderBottomWidth;

    &::before {
      position: relative;
      content: attr(data-step);
      display: inline-block;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background: $brownMediumActiveColor;
      color: $whiteColor;
      line-height: 29px !important;
      font-size: 16px;

      @media (min-width: $screen-tablet-portrait) {
        line-height: 28px !important;
        font-size: 18px;
      }
    }

    &--done {
      opacity: 1;
      cursor: pointer;

      @include iconFont(checkSmall) {
        font-size: 16px;
        background: $terracottaColor;
        line-height: 32px !important;
      }

      #{$root}__step-label {
        color: $terracottaColor;
      }
    }

    &--completed {
      cursor: pointer;
    }

    &--active {
      opacity: 1;

      &::before {
        background: $orangeColor;
        color: $whiteColor;
      }
    }
  }

  &__step-label {
    white-space: nowrap;
    color: $brownMediumActiveColor;
    display: block;
    border-bottom: 2px transparent solid;
    padding-bottom: 4px;
    margin-top: 4px;

    @media (min-width: $screen-tablet-portrait) {
      margin-top: 8px;
    }

    #{$root}__step--active & {
      opacity: 1;
      color: $orangeColor;
      border-color: currentColor;
    }
  }
}
