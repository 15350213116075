@import '../../../bps/components/voucherForm/voucherFormLegacy.scss';

.voucher-form {
  &__remove {
    @media (max-width: $screen-mobile-landscape-max) {
      margin-right: 0;
      order: 2;
      min-width: 50px;
      position: relative;
      padding: 3px 8px;
    }

    @media (min-width: $screen-tablet-portrait) {
      position: absolute;
      width: 40px;
      margin-right: -16px;
      right: -10px;
      top: 0;
    }

    @media (min-width: $screen-tablet-landscape) {
      right: -9px;
      top: -10px;
    }

    @media (min-width: $screen-desktop) {
      right: 17px;
    }

    &::before {
      font-size: 14px;
      content: map-get($icons, 'close');
      position: static;
    }
  }

  &__submit-button {
    --button-secondary-text-color: #{$whiteColor};
    --button-secondary-background-color: #{$brownMediumColor};
    --button-secondary-border-color: #{$brownMediumColor};
    --button-secondary-hover-background-color: #{$brownMediumHoverColor};
    --button-secondary-hover-text-color: #{$whiteColor};
    --button-secondary-active-background-color: #{$brownMediumActiveColor};
    margin: 0 0 0 16px;
    min-width: 170px;
    width: auto;

    @media (max-width: $screen-mobile-sm) {
      padding: 0;
    }

    @media (max-width: $screen-mobile-large) {
      min-width: 120px;
    }
  }

  &__info {
    font-size: 13px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 13px;
    }

    .info-popup__info {
      background: $brownMediumHoverColor;

      &::before {
        color: $whiteColor;
      }
    }
  }

  &__encashed-text,
  &__encashed-code {
    font-weight: $fontWeightRegular;
    font-size: 16px;
  }

  &__encashed-code {
    @media (max-width: $screen-mobile-landscape-max) {
      order: 3;
      align-items: flex-end;
      width: auto;
      min-width: 70px;
    }

    @media (min-width: $screen-tablet-portrait) {
      text-align: right;
      padding-right: 16px;
      width: auto;
    }

    @media (min-width: $screen-tablet-landscape) {
      padding-right: 32px;
      width: 160px;
    }
  }

  &__encashed-text {
    padding: 0;

    @media (max-width: $screen-mobile-landscape-max) {
      order: 1;
      flex-grow: 2;
    }
  }

  &__encashed-row {
    justify-content: flex-end;
    padding: 0 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      justify-content: space-between;
      padding: 0 0 16px 8px;
    }

    @media (min-width: $screen-tablet-landscape) {
      justify-content: flex-end;
      padding: 0 16px 16px 8px;
    }

    @media (min-width: $screen-desktop) {
      padding: 0 41px 16px 8px;
    }

    @media (min-width: 1500px) {
      padding-right: 45px;
    }
  }

  .input__wrapper {
    border: 1px solid $grayLightColor;
    border-radius: 2px;
  }

  &__group {
    @media (max-width: $screen-mobile-landscape-max) {
      flex-wrap: wrap;
      gap: 16px;

      .button.form__submit-button {
        margin: 0;
        width: 100%;
      }
    }
  }
}
