.checkout-step-summary-payment-express {
  margin-bottom: 16px;

  &__message {
    @include container();
  }

  &__form-box {
    background: $sandHoverColor;
    border-radius: 3.8px;
    padding: 24px 10px;
    margin: 0;
    border: 0 none;

    @media (min-width: $screen-tablet-portrait) {
      padding: 24px 15px;
      width: calc(50% - 14px);
      height: auto;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: calc(33.33% - 28px);
    }

    @media (min-width: $screen-desktop) {
      padding: 24px 28px;
    }
  }

  .checkout-step-summary__checkout-box {
    justify-content: space-between;
  }

  &__date-input {
    margin-bottom: 8px;
  }

  &__select {
    .select__selected-value {
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  &__submit-box {
    align-items: flex-start;
    padding-bottom: 24px;
    padding-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__birthday-message {
    padding-bottom: 8px;
    width: 100%;

    @media (min-width: $screen-mobile-landscape) {
      width: 49%;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 32%;
      padding-top: 13px;
    }

    @media (min-width: $screen-desktop) {
      width: 27%;
    }
  }

  &__date-input-container {
    padding: 0;
    width: 100%;

    @media (min-width: $screen-mobile-landscape) {
      width: 49%;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 32%;
    }

    @media (min-width: $screen-desktop) {
      width: 39%;
    }
  }

  &__submit-button {
    width: 100%;

    @media (min-width: $screen-mobile-landscape) {
      text-align: right;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 32%;
    }

    .form__mandatory-text {
      display: block;
      margin: 4px 0 0;
    }
  }
}
