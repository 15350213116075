.order-summary {
  $borderStyle: 1px solid rgb(146 117 101 / .2);

  & > &__row,
  & > &__row-summary {
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: $screen-tablet-portrait) {
      padding-left: 28px;
      padding-right: 28px;
    }
  }

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
      max-width: 460px;
      margin-left: auto;
      justify-content: flex-end;
      flex-wrap: nowrap;
    }

    & & {
      margin: 4px 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &--full {
      @media (min-width: $screen-tablet-portrait) {
        width: 100%;
        max-width: none;
      }
    }

    &--full-line {
      display: block;

      @media (min-width: $screen-tablet-portrait) {
        width: 100%;
        max-width: none;
      }

      @media (min-width: $screen-desktop-sm) {
        padding: 0;
      }

      &.order-summary__row {
        padding: 4px 0;
      }
    }

    &--top {
      .order-summary__row {
        @media (min-width: $screen-tablet-landscape) {
          max-width: none;
        }
      }

      .order-summary__col-summary-left {
        order: 2;
        width: 100%;
        border: 0 none;
        padding: 6px 0;
        margin: 0;

        @media (min-width: $screen-tablet-portrait) {
          order: 1;
          margin: 0;
          padding: 4px 0;
          align-self: flex-end;
          width: 50%;
        }

        @media (min-width: $screen-tablet-landscape) {
          width: 30%;
        }

        .checkout-step-summary & {
          padding: 0;
        }

        &--empty {
          display: none;

          @media (min-width: $screen-tablet-portrait) {
            display: block;
          }
        }
      }

      .order-summary__col-summary-right {
        order: 1;
        width: 100%;

        @media (min-width: $screen-tablet-portrait) {
          order: 2;
          width: 50%;
        }

        @media (min-width: $screen-tablet-landscape) {
          width: 70%;
          padding-right: 16px;
        }

        @media (min-width: $screen-desktop) {
          padding-right: 41px;
        }

        @media (min-width: 1500px) {
          padding-right: 45px;
        }
      }
    }

    &--top-second {
      margin: 16px 0 0;

      @media (min-width: $screen-tablet-portrait) {
        border-top: 0 none;
        margin: 0;
        padding: 0 0 3px;
      }
    }

    &--total {
      @media (min-width: $screen-desktop) {
        margin: 0;
        justify-content: flex-end;
        float: right;
        flex-flow: row nowrap;
      }
    }

    &--main {
      padding-bottom: 0;
    }

    &--small {
      padding-top: 0;
    }

    &--discount {
      position: relative;

      @media (min-width: $screen-tablet-portrait) {
        padding-right: 16px;
      }

      @media (min-width: $screen-tablet-landscape) {
        padding-right: 32px;
      }

      .order-summary__value {
        color: $textSuccessColor;
        padding-right: 0;

        @media (min-width: $screen-tablet-portrait) {
          width: 128px;
        }
      }
    }

    &--extra-promotion {
      .order-summary__small-text {
        font-size: 13px;
        font-weight: $fontWeightRegular;
        color: $brownMediumActiveColor;
      }

      .extra-promotion {
        &__highlight {
          white-space: nowrap;
        }

        &__upselling-highlight {
          color: $orangeColor;
          white-space: nowrap;
        }

        &__active {
          color: $textSuccessColor;
          white-space: nowrap;
        }
      }
    }

    &--border-top {
      border-top: 1px solid $grayLightColor;
      padding: 5px 8px;

      @media (min-width: $screen-tablet-portrait) {
        margin: 4px 0 0;
        padding: 8px 0 4px;
      }
    }

    .checkout-step-delivery & {
      width: 100%;

      @media (min-width: $screen-tablet-landscape) {
        width: 100%;
        float: right;
      }
    }
  }

  &__small-text {
    font-size: 13px;
    color: $brownMediumActiveColor;
    display: block;
    font-weight: $fontWeightRegular;
    line-height: 1.4;
    padding: 0 0 2px;
  }

  &__key,
  &__value {
    font-family: $fontPrimaryFamily;
    font-size: 16px;
    line-height: 1.4;

    &--secondary {
      color: $textGrayColor;
    }

    &--small {
      font-size: $fontSizeSmall;
    }

    .order-summary__row--success & {
      color: $textSuccessColor;
    }
  }

  &__key {
    width: 70%;
    text-align: left;

    @media (min-width: $screen-tablet-landscape) {
      text-align: right;
      width: 49%;
    }

    &--full {
      width: 100%;
    }

    &--total {
      font-size: 20px;
    }
  }

  &__key-list-item {
    display: block;
  }

  &__key-value {
    width: 100%;
    text-align: right;
  }

  &__value {
    width: 30%;
    text-align: right;

    @media (min-width: $screen-tablet-portrait) {
      padding-right: 16px;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: 160px;
      padding-right: 32px;
    }

    &--total {
      font-size: 20px;
      white-space: nowrap;
    }
  }

  &__remove {
    color: $removeColor;
    text-decoration: none;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    padding: 8px;
    width: 32px;
    text-align: center;
    transform: translate(100%, 15px);
    right: calc(30% + 16px);
    top: -18px;

    @media (min-width: $screen-mobile-large) {
      right: calc(25% + 16px);
    }

    @media (min-width: $screen-tablet-portrait) {
      right: 0;
      top: 0;
      transform: translate(20px, -4px);
    }

    @media (min-width: $screen-tablet-landscape) {
      right: -17px;
    }

    @media (min-width: $screen-desktop) {
      right: -20px;
    }

    &:hover {
      text-decoration: none;

      @media (min-width: $screen-tablet-portrait) {
        color: $removeHoverColor;
      }
    }

    @include iconFont(close) {
      font-size: 14px;
    }
  }

  &__additional-info {
    font-size: 13px;
    display: block;
    margin: 2px 0;
    color: $brownMediumActiveColor;

    a {
      color: $brownMediumActiveColor;
      text-decoration: underline;

      &:visited {
        color: $brownMediumActiveColor;
      }
    }

    &--small-text {
      margin: 0;

      .order-summary__small-text {
        margin: 0;
      }
    }
  }

  &__promo-code-form {
    width: 100%;
    padding: 10px;
    background: $backgroundWhiteColor;
  }

  &__voucher-info {
    color: $grayDarkColor;
    position: relative;

    &--savings {
      html[lang="de-CH"] & {
        @media (min-width: $screen-tablet-portrait) {
          padding-right: 0;
        }

        .order-summary__value {
          @media (min-width: $screen-tablet-portrait) {
            padding-right: 16px;
          }

          @media (min-width: $screen-tablet-landscape) {
            padding-right: 32px;
          }
        }
      }
    }
  }

  &__shipping-info {
    font-size: 16px;

    @media (min-width: $screen-tablet-landscape) {
      width: 50%;
      margin-left: auto;
    }

    &--not-free {
      color: $textBaseColor;

      .order-summary__key {
        padding: 0 16px 8px 0 !important;

        @media (min-width: $screen-tablet-landscape) {
          padding: 0 32px 8px 0 !important;
        }
      }

      strong {
        background: $orangeColor;
        font-weight: $fontWeightRegular;
        color: $whiteColor;
        padding: 1px 4px 3px;
        white-space: nowrap;
        border-radius: 2px;
      }

      & > * {
        width: 100% !important;
      }
    }

    &--text {
      color: $textSuccessColor;
    }

    &--free {
      color: $textSuccessColor;
    }
  }

  &__placeholder {
    @include componentPreloader() {
      height: auto;
    }
  }

  &__row-summary {
    width: 100%;
    border-bottom: $borderStyle;
    padding: 0 0 12px;

    @media (min-width: $screen-tablet-portrait) {
      border-top: $borderStyle;
      display: flex;
      justify-content: center;
      margin: 8px 0 0;
      padding: 10px 0 15px;
    }

    @media (min-width: $screen-tablet-landscape) {
      margin: 13px 0 0;
    }

    &--with-basket-content {
      @media (min-width: $screen-tablet-landscape) {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .order-summary__col-summary-left {
        @media (max-width: $screen-mobile-landscape-max) {
          border-bottom: 1px solid transparent;
          position: relative;

          &::after {
            width: calc(100% + 40px);
            height: 1px;
            background: rgb(146 117 101 / .2);
            content: '';
            position: absolute;
            bottom: 0;
            left: -20px;
          }
        }
      }
    }

    .order-summary__col-summary-right {
      .order-summary__row--small {
        @media (min-width: $screen-desktop) {
          .order-summary__key {
            width: 51.2%;
          }

          .order-summary__value {
            width: 48.8%;
          }
        }
      }
    }
  }

  &__col-summary-left {
    width: 100%;
    border-bottom: $borderStyle;
    padding: 0 0 7px;
    margin: 0 0 9px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0;
      margin: 0;
      border-bottom: 0 none;
      display: flex;
      justify-content: center;
      flex-flow: column;
      align-self: center;
      width: 50%;
    }
  }

  &__col-summary-right {
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
    }

    @media (min-width: $screen-tablet-landscape) {
      padding-right: 16px;
    }

    @media (min-width: $screen-desktop) {
      padding-right: 41px;
    }

    @media (min-width: 1500px) {
      padding-right: 45px;
    }

    .order-summary__row {
      width: 100%;

      .order-summary__row--main {
        @media (min-width: $screen-desktop-sm) {
          vertical-align: bottom;
          display: flex;
          justify-content: flex-end;
          flex-direction: row;
        }
      }

      .order-summary__key--main {
        @media (min-width: $screen-tablet-landscape) {
          line-height: 1;
          align-self: flex-end;
          padding: 0 0 2px;

          html[lang="de-CH"] & {
            padding: 0 0 4px;
          }
        }
      }
    }
  }

  &__sprites {
    @media (max-width: $screen-mobile-landscape-max) {
      transform: scale(.72);
      transform-origin: left 15px;
      width: 136%;
    }

    > * {
      display: inline-block;
      text-transform: uppercase;
      margin: 1px 7px 0 0;
      font-weight: 600;

      @media (min-width: $screen-tablet-portrait) {
        margin: 8px 4px 0 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin: 8px 9px 0 0;
      }
    }
  }

  &__basket-content {
    @media (min-width: $screen-tablet-portrait) {
      padding: 4px 0 8px;
    }
  }

  &__col-summary-full {
    width: 100% !important;

    .order-summary__row {
      width: 100% !important;

      @media (min-width: $screen-desktop-sm) {
        padding: 3px 0 !important;
      }
    }

    .order-summary__key,
    .order-summary__value {
      width: auto !important;
    }

    .order-summary__additional-info--small-text {
      width: 100% !important;
      float: left;
      text-align: left;
    }
  }

  &__basket-item-groups--many {
    .delivery-forecast-message {
      display: none;
    }
  }

  &__delivery-forecast {
    &--head {
      .delivery-forecast-message {
        display: flex;
        margin: 0 0 16px;
      }
    }
  }

  .delivery-forecast-message {
    font-size: 13px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 14px;
      margin-right: 16px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 16px;
      margin-right: 0;
    }
  }

  html[lang="de-CH"] & {
    .order-summary__key--total,
    .order-summary__value--total {
      @media (max-width: $screen-mobile-landscape-max) {
        width: auto;
      }
    }

    .order-summary__key {
      width: 60%;

      @media (min-width: $screen-tablet-landscape) {
        width: 49%;
      }
    }

    .order-summary__value {
      width: 40%;

      @media (min-width: $screen-tablet-landscape) {
        width: 160px;
      }
    }
  }
}
