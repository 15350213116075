.order-summary-small {
  font-size: $orderSummarySmallFontSize;

  &__row {
    display: flex;
    margin: 0 0 16px;
    line-height: 130%;

    &--summary {
      border-top: 2px solid $checkoutBorderDark;
      border-bottom: 2px solid $checkoutBorderDark;
      padding: 16px 0;
      flex-wrap: wrap;
    }

    &--no-margin {
      margin: 0;
    }

    &--discount {
      position: relative;

      .order-summary-small__value {
        color: $textSuccessColor;
      }
    }

    &--total-savings,
    &--shipping-free {
      color: $textSuccessColor;

      .order-summary-small__small-text {
        color: $textBaseColor;
      }
    }

    &--full {
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-end;
      margin: 0;
    }

    &--border-top {
      border-top: 1px solid $grayDarkColor;
      margin: 16px 0 0;
      padding: 16px 0;
    }
  }

  &__key {
    width: 72%;
    font-weight: $orderSummarySmallFontWeight;
  }

  &__value {
    text-align: right;
    font-weight: $orderSummarySmallFontWeight;
    flex-grow: 1;

    &--discount {
      color: $textSuccessColor;
    }

    &--total {
      font-size: $orderSummaryTotalFontSize;
      font-weight: $orderSummarySmallTotalFontWeight;
      white-space: nowrap;
    }
  }

  &__small-text {
    display: block;
    font-weight: $orderSummarySmallSmallTextFontWeight;
    font-size: $orderSummarySmallSmallTextFontSize;
  }

  &__key-list-item {
    display: block;
  }

  &__voucher-info {
    color: $checkoutBorderDark;
    position: relative;

    .order-summary-small__key {
      width: 100%;
      align-self: flex-end;
    }

    &--savings {
      .order-summary-small__key {
        width: 75%;
      }

      .order-summary-small__value {
        align-self: flex-end;
        width: 25%;
      }
    }
  }
}
