.register-form {
  @media (min-width: $screen-tablet-portrait) {
    margin-right: 40px;
  }

  &__subheadline {
    font-size: 21px;
    margin: 24px 0 16px;
    font-family: $fontHeadlineFamily;
    font-weight: $formHeadlineFontWheight;
    line-height: 120%;
  }

  &__form__carof_label {
    line-height: 110%;
    padding-left: 10px;
    cursor: pointer;
    font-size: $fontSizeXSmall;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeSmall;
      padding-left: 16px;
    }
  }

  &__address-section {
    margin-left: 16px;
  }

  &__address-addition-toggle {
    margin-top: 0;
  }

  &__compliance {
    margin: 24px 0 -8px;

    & + .form__group {
      margin-top: 24px;
    }
  }

  &__placeholder {
    @include componentPreloader() {
      height: auto;
    }
    min-height: 300px;
  }

  &-error {
    margin-bottom: 8px;
  }

  .address-form-inputs {
    &__input-title {
      max-width: 180px;
    }
  }

  .form__col--sub-padding {
    padding: 0 0 24px;
  }
}
