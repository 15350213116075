@import '../../../bps/components/orderSummarySmall/orderSummarySmallLegacy.scss';

.order-summary-small {
  &__key {
    width: 70%;
    font-size: 17px;
    font-weight: $fontWeightRegular;

    &--total {
      font-size: 21px;
    }
  }

  &__value {
    font-size: 17px;
    font-weight: $fontWeightRegular;

    &--total {
      font-size: 21px;

      .order-summary-small__small-text {
        font-size: 13px;
        color: $brownMediumHoverColor;
        padding: 3px 0 0;
      }
    }
  }

  &__row {
    margin: 0 0 24px;

    &--summary {
      border-top: 1px solid rgb(146 117 101 / .2);
      border-bottom: 1px solid rgb(146 117 101 / .2);
      padding: 16px 0;
      flex-wrap: wrap;

      @media (min-width: $screen-tablet-portrait) {
        padding: 24px 0;
      }
    }

    &--no-margin {
      margin: 0 !important;
    }
  }
}
