.payment-method-form {
  $root: &;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__debit-row {
    max-width: 400px;
  }

  &__debit-button-row {
    display: flex;
    justify-content: space-between;

    .button--cancel {
      line-height: 25px;
      min-width: auto;

      &::after {
        display: none;
      }
    }
  }

  &__billing-text {
    margin: -6px 0 6px 33px;
    max-width: 390px;

    .radio-input--selected + & {
      margin: 0 0 32px 33px;
    }

    .checkbox__label {
      font-size: 10px;
      color: $grayDarkColor;
    }

    .checkbox__input:checked + .checkbox__box + .checkbox__label {
      color: $grayDarkColor;
    }

    a {
      text-decoration: none;
      font-weight: $fontWeightBold;
    }
  }

  &__billing-text-info {
    margin: 4px 0 8px;
    font-size: 10px;
    line-height: 1.3;
    color: $grayDarkColor;
  }

  .radio-input {
    margin-bottom: 24px;
  }

  .form__radio-line--open {
    .form__group__inner-form { // stylelint-disable-line
      margin-top: 0;
    }

    .radio-input--selected {
      margin-bottom: 24px;

      &[modelvalue="CREDITCARD"],
      &[modelvalue="DIRECT_DEBIT"] {
        margin-bottom: 8px;
      }

      &[modelvalue="PAY_PAL"] {
        margin-bottom: 4px;
      }
    }
  }

  .radio-input__label {
    font-family: $fontPrimaryFamily;
    font-weight: $paymentMethodFormLabelFontWeight;
    padding: 0 8px 0 32px;
    width: 100%;
    margin-top: -10px;
  }

  .radio-input__description {
    padding-left: 96px;
    margin-top: -8px;
  }

  .form__group__inner-form { // stylelint-disable-line
    min-width: 80%;
    max-width: 100%;
    padding-bottom: 24px;
    margin: 0 0 8px 32px;

    .button--secondary-small {
      @include switchButtonStyle('secondary>primary');
    }
  }

  .form__group--row-button {
    margin-top: 0;
  }

  .form__group--row {
    margin-left: -16px;
  }

  .radio-input__input:checked ~ .radio-input__label {
    color: $paymentMethodFormLabelCheckedColor;

    .user-area & {
      color: $textBaseColor;
    }
  }

  &__direct-debit-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    #{$root}__delete,
    #{$root}__edit {
      font-size: $paymentMethodFormEditFontSize;
      font-weight: $fontWeightSemiBold;
      text-decoration: underline;
      text-transform: $paymentMethodFormEditTextTransform;
      position: relative;
      top: 10px;
      cursor: pointer;
    }

    #{$root}__edit {
      margin-right: 12px;
    }

    #{$root}__direct-debit-edit-form {
      width: 100%;
    }
  }

  &__direct-debit-box {
    .radio-input {
      width: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0;
      min-height: 40px;

      &--hidden-radio {
        .radio-input__radio {
          visibility: hidden;
        }
      }
    }

    .radio-input__radio {
      top: auto;
    }

    .radio-input__label {
      margin-top: 0;
    }

    .payment-method-form__edit,
    .payment-method-form__delete {
      top: 0;
    }

    .radio-input--selected {
      margin: 0 !important;
    }
  }

  &__edit-buttons {
    padding: 0 0 0 38px;
    display: flex;

    @media (min-width: $screen-tablet-landscape) {
      padding: 0;
    }
  }

  &__option-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 4px 15px;

    @media (min-width: $screen-tablet-landscape) {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__creditcard-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $paymentMethodCCOptionFontSize;

    #{$root}__option-name {
      display: flex;
      flex-direction: column;

      @media (min-width: $screen-tablet-landscape) {
        padding: 0 16px 0 0;
      }
    }

    #{$root}__expires-at--colored {
      color: $redColor;
    }
  }

  &__creditcard-list-item {
    display: flex;
    align-items: center;

    #{$root}__delete {
      font-size: $paymentMethodFormEditFontSize;
      font-weight: $fontWeightSemiBold;
      text-decoration: underline;
      text-transform: $paymentMethodFormEditTextTransform;
      position: relative;
      top: -4px;
      cursor: pointer;
      margin: auto 0;
      bottom: 0;

      @media (min-width: $screen-tablet-landscape) {
        top: 3px;
        bottom: auto;
        margin: 0;
      }
    }

    .radio-input__label {
      margin-top: 0;
    }

    label.radio-input {
      width: auto;

      &--hidden-radio {
        .radio-input__radio {
          visibility: hidden;
        }
      }
    }
  }

  &__creditcard-box {
    margin: 0;

    .radio-input {
      min-height: 40px;
      justify-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__radio {
        top: 0;
        left: 0;
        margin: auto;
        bottom: 0;
      }
    }

    .radio-input__input:checked ~ .radio-input__label {
      color: $textBaseColor;
    }

    > .form__group .radio-input {
      margin-bottom: 8px;
    }

    .form__group__inner-form { // stylelint-disable-line
      margin: 0 0 8px 32px;
    }

    .radio-input__label {
      margin-top: 0;
    }

    iframe {
      min-height: 42px;
    }

    .is-test-env .form__radio-line--open & {
      height: auto !important;
    }
  }

  .radio-input__sprite-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;

    > [class^="sprite-"] {
      display: block;
      position: relative;
      left: auto;
      top: auto;
      min-width: 64px;
    }

    > .radio-input__sprite-text {
      padding: 0;
    }
  }
}
