@import '../../../bps/components/productOrderFreeItem/productOrderFreeItemLegacy.scss';

.product-order-free-item {
  &__wrapper {
    padding: 16px 20px 20px;

    @media (min-width: $screen-tablet-landscape) {
      padding: 20px 28px 28px;
    }
  }

  &__container {
    justify-content: center;
  }

  &__container-center {
    margin: 0;
  }

  &__container-left,
  &__container-right {
    display: none;
  }

  &__headline {
    font-size: 17px;
    font-family: $fontHeadlineFamily;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 20px;
    }
  }

  &__short-description,
  &__description3 {
    font-family: $fontPrimaryFamily;
  }

  &__add-to-basket-button {
    --button-secondary-text-color: #{$whiteColor};
    --button-secondary-background-color: #{$brownMediumColor};
    --button-secondary-border-color: #{$brownMediumColor};
    --button-secondary-hover-background-color: #{$brownMediumHoverColor};
    --button-secondary-hover-text-color: #{$whiteColor};
    --button-secondary-active-background-color: #{$brownMediumActiveColor};
    height: auto;
    line-height: 32px;
    min-height: 32px;

    &--selected {
      --button-secondary-hover-background-color: #{$greenColor};
      --button-secondary-hover-text-color: #{$whiteColor};

      &::after {
        font-size: 12px !important;
        line-height: 20px !important;
        transform: none !important;
      }
    }

    &--not-available {
      font-size: 13px;
      letter-spacing: 0;
      padding: 6px;

      &::after {
        display: none;
      }
    }
  }
}
