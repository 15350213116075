.checkout-step-login {
  @media (min-width: $screen-tablet-portrait) {
    margin-top: 40px;
  }

  &__headline {
    font-size: 24px;
    line-height: 115%;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeXXMedium;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
  }

  &__info {
    font-size: $fontSizeXSmall;
    line-height: 130%;
    margin: 8px 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeSmall;
      margin: 8px 0 24px;
    }
  }

  &__register-button {
    @media (min-width: $screen-tablet-portrait) and (max-width: $screen-tablet-landscape) {
      padding: 0 16px 0 24px;
    }
  }

  &__guest-link {
    color: $orangeColor;
    font-weight: $fontWeightRegular;
    letter-spacing: 1px;
    border-bottom: 0 none;

    @media (min-width: $screen-tablet-portrait) {
      letter-spacing: 1.6px;
    }

    &:hover {
      color: $orangeHoverColor;
    }

    &:active {
      color: $orangeActiveColor;
    }
  }
}
