.request-password-page {
  &__wrapper {
    background: transparent;

    @media (min-width: $screen-tablet-portrait) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .form__text-info {
    font-size: $fontSizeSmall;
    margin: 16px 0;
  }

  &__icon {
    margin: 0 20px 0 0;

    &::before {
      font-size: 35px;
      color: $brandPrimaryColor;
    }

    &--mail {
      @include iconFont(mail, before);
    }

    &--checked {
      @include iconFont(checked, before);

      &::before {
        background: $brandPrimaryColor;
        color: $whiteColor;
        border-radius: 5px;
        font-size: 25px;
        padding: 5px;
      }
    }
  }
}
