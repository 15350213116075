@import '../../../bps/components/checkoutStepSummary/checkoutStepSummaryLegacy.scss';

.checkout-step-summary {
  $borderStyle: 1px solid rgb(146 117 101 / .2);

  &__headline {
    padding: 0;
  }

  &__products-order-table {
    width: 100%;

    @media (min-width: $screen-tablet-landscape) {
      margin-top: 24px;
    }

    .products-order-table__header {
      display: none;

      @media (min-width: $screen-tablet-portrait) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: $borderStyle;
        border-top: 0 none;
        margin: 16px 0 0;
        padding: 16px 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin: 40px 0 0;
      }
    }
  }

  &__message:not(:empty) {
    .general-message {
      margin-bottom: 6px;
    }
  }

  &__checkout-box {
    gap: 28px;
    margin: 0 auto;
    align-items: stretch;

    .checkout__box {
      background: $whiteColor;
      border-radius: 3.8px;
      padding: 24px 10px;
      margin: 0;
      border: 0 none;

      @media (min-width: $screen-tablet-portrait) {
        padding: 24px 15px;
      }

      @media (min-width: $screen-desktop) {
        padding: 24px 28px;
      }

      &--invoice,
      &--delivery {
        @media (min-width: $screen-tablet-portrait) {
          width: calc(50% - 14px);
          height: auto;
        }

        @media (min-width: $screen-tablet-landscape) {
          width: calc(33.33% - 28px);
        }
      }

      &--payment {
        @media (min-width: $screen-tablet-portrait) {
          width: 100%;
          height: auto;
        }

        @media (min-width: $screen-tablet-landscape) {
          width: 33.33%;
        }
      }

      .checkout__box-content--list {
        .checkout__box-text,
        .delivery-forecast-message__prefix,
        .delivery-forecast-message {
          font-size: 13px;

          @media (min-width: $screen-tablet-portrait) {
            font-size: 17px;
          }
        }

        .delivery-forecast-message__date-text {
          font-size: 13px;

          @media (min-width: $screen-tablet-portrait) {
            font-size: 13px;
          }
        }
      }
    }

    .checkout__box-button {
      margin-left: 0;
    }
  }

  .checkout__box-header {
    @media (min-width: $screen-tablet-portrait) {
      justify-content: space-between;
    }
  }

  &__footer-button-col {
    > .button {
      min-width: 235px;
    }
  }

  &__newsletter {
    display: block;
    width: 100%;
    padding: 12px 10px 16px;
    background: $brownLightColor;

    @media (min-width: $screen-mobile-large) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
      padding: 12px 20px 16px;
      border-bottom: $borderStyle;
    }

    &__label {
      max-width: $checkoutStepSummaryCheckboxMaxWidth;
      padding: $checkoutStepSummaryCheckboxPadding;
      display: flex;
      cursor: pointer;

      .checkbox__box {
        left: -2px;
        top: 7px;
        transform: none;
        border-color: $grayLightColor;
      }

      .checkbox__label {
        font-size: $checkoutStepSummaryCheckboxLabelFontSizeMobile;
        font-family: $fontHeadlineFamily;
        font-weight: $fontWeightSemiBold;
        line-height: $checkoutStepSummaryCheckboxLabelLineHeight;
        color: $textDarkestColor;

        @media (min-width: $screen-tablet-portrait) {
          font-size: $checkoutStepSummaryCheckboxLabelFontSizeDesktop;
        }
      }

      a {
        color: $checkoutStepSummaryCheckboxLabelColor;
      }

      strong {
        font-weight: $fontWeightSemiBold;
        color: $orangeColor;
      }
    }

    &__declaration {
      font-size: $checkoutStepSummaryCheckboxDeclarationFontSize;
      line-height: 1.4;
      color: $checkoutStepSummaryCheckboxLabelColor;
      padding: 8px 0 0;
      max-width: $checkoutStepSummaryCheckboxMaxWidth;

      a {
        color: $checkoutStepSummaryCheckboxLabelColor;
      }
    }

    .checkbox--switch + .checkout-step-summary__newsletter__declaration { // stylelint-disable-line
      padding: $checkoutStepSummarySwitchPadding;
    }
  }

  &__order-voucher-section {
    padding: 0 20px 20px;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    width: 100%;
    background: $whiteColor;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 28px 24px;
      border-radius: 0 0 4px 4px;
    }
  }

  .products-order-table__wrapper-inner {
    margin: 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 auto;
    }
  }

  &__headline-basket {
    background: $whiteColor;
    border-radius: 3.8px 3.8px 0 0;
    padding: 24px 10px 0;
    border: 0 none;
    margin: 24px 10px 0;
    width: calc(100% - 20px);

    @media (min-width: $screen-mobile) {
      margin: 24px 20px 0;
      width: calc(100% - 40px);
    }

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      height: 1px;
      background: rgb(146 117 101 / .2);
      width: calc(100% + 20px);
      margin: 18px 0 0 -10px;
    }
  }

  @media (max-width: $screen-mobile-landscape-max) {
    .order-summary > .order-summary__row,
    .order-summary > .order-summary__row-summary {
      padding-left: 10px;
      padding-right: 10px;
    }

    .products-order-table__product {
      padding: 16px 2px 14px 0 !important;
    }

    // stylelint-disable-next-line
    .cart-page__footer__container,
    .products-order-table__wrapper {
      @include container($maxWidthInner);
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;
    }

    .cart-page__order-summary {
      padding: 16px 0 0;
    }

    .order-summary__row-summary .order-summary__col-summary-left {
      margin: 0 0 9px -10px;
      width: calc(100% + 20px);
    }

    .voucher-form__code {
      padding: 16px 0 0;
    }

    .product-order-free-item__wrapper {
      margin: 0 -10px;
    }

    .checkout-step-summary__order-voucher-section {
      padding: 0 10px 10px;
      border-radius: 0 0 3.8px 3.8px;
    }
  }

  .apple-pay-button {
    border-radius: 2px;
  }
}
