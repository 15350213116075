.checkout {
  &__box {
    margin: 0 0 24px;
    border-bottom: 1px solid rgb(146 117 101 / .2);
    padding-bottom: 24px;

    &--no-border {
      border: 0;
      padding: 0;
    }
  }

  &__box-header {
    font-size: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    line-height: 130%;
    gap: 8px;

    @media (min-width: $screen-mobile) {
      font-size: 18px;
    }

    @media (min-width: $screen-tablet-portrait) {
      font-size: 21px;
    }

    > span {
      font-weight: $fontWeightSemiBold;
    }

    &--sidebar {
      font-size: $formHeadlineFontSizeMobile;
      font-family: $fontHeadlineFamily;
      font-weight: $fontWeightSemiBold;
      margin: $formHeadlineMarginMobile;
      line-height: 1;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $formHeadlineFontSizeDesktop;
        margin: 0 0 34px;
      }
    }
  }

  &__box-content {
    font-size: $fontSizeSmall;
    color: $grayDarkColor;
    line-height: 150%;

    &--pulse {
      animation: pulse;
      animation-duration: 2s;
      animation-delay: 1s;
    }
  }

  &__box-text {
    padding: 0 0 15px;
  }

  &__box-icon {
    display: inline-block;
    position: relative;

    > span {
      display: inline-block;
      z-index: 1;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &__box-sprite {
    &--PAY_PAL {
      @extend .sprite-paypal;
    }

    &--PAY_PAL_EXPRESS {
      @extend .sprite-paypalexpress;
    }

    &--CREDITCARD {
      @extend .sprite-creditcard;
    }

    &--DIRECT_DEBIT {
      @extend .sprite-sepa;
    }

    &--BANK_TRANSFER {
      @extend .sprite-rechnung;
    }

    &--GIFT_VOUCHER {
      @extend .sprite-giftvoucher;
    }

    &--POST_FINANCE_CARD,
    &--POST_FINANCE_E_FINANCE {
      @extend .sprite-postfinance;
    }

    &--APPLE_PAY {
      @extend .sprite-apple-pay;
    }

    &--APPLE_PAY_EXPRESS {
      @extend .sprite-apple-pay;
    }
  }

  &__box-button {
    transition: background .15s linear, border-color .15s linear, color .15s linear, box-shadow .15s linear;
    background: $brownMediumColor;
    border: $buttonBorderWidth solid $brownMediumColor;
    border-radius: $buttonBorderRadius;
    color: $whiteColor;
    display: inline-block;
    width: auto;
    text-transform: uppercase;
    padding: 0 12px 0 16px;
    font-size: $buttonFontSizeMobile;
    letter-spacing: 1px;
    line-height: 32px;
    height: 32px;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include iconFont(arrowRight, after) {
      display: inline-block;
      height: 14px;
      font-size: 12px;
      padding: 0 0 0 4px;
      vertical-align: middle;
      transition: color .15s linear, transform .15s ease-in-out;
    }

    body:not(.is_touch) & {
      &:hover {
        background: $brownMediumHoverColor;

        &::after {
          transform: translateX(4px);
        }
      }
    }

    &:active {
      background: $brownMediumActiveColor;

      &::after {
        transform: translateX(4px);
      }
    }

    .is_fire-fox & { // stylelint-disable-line
      text-overflow: clip;
    }
  }

  &__no-padding-bottom {
    padding-bottom: 0 !important;
  }

  &__text {
    font-size: $baseFontSize;
    color: $grayDarkColor;
    font-weight: $fontWeightRegular;
    line-height: $fontLineHeightRegular;

    p {
      padding: 0 0 20px;
      margin: 0;

      &:last-child {
        padding: 0;
      }
    }

    a {
      color: $grayDarkColor;
      font-weight: $fontWeightRegular;
      text-decoration: none;

      &:visited {
        color: $grayDarkColor;
      }

      &:hover {
        color: inherit;
        text-decoration: underline;
        text-decoration-color: $brandHighlightColor;
      }
    }
  }

  &__summary {
    padding: 0;

    &--padding {
      padding: 5px 15px;
    }

    &--left {
      font-size: $fontSizeMedium;
      text-align: left;
    }

    &--right {
      font-size: $fontSizeXXMedium;
      text-align: right;
    }

    &--bold {
      font-weight: $fontWeightBold;
    }

    &--small {
      font-size: $fontSizeSmall;
    }
  }

  &__product-list {
    margin: 25px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }
  }

  &__row {
    padding-bottom: 15px;

    &--border-top {
      border-top: 1px solid $brownMediumLightColor;
      padding-top: 25px;
    }

    &--border-bottom {
      border-bottom: 1px solid $brownMediumLightColor;
      padding-bottom: 25px;
    }
  }

  &__footer-button {
    margin: 15px 0 0;
    order: 3;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
  }

  &__back-button {
    width: 100%;
    order: 2;
    margin-top: 20px;
    align-self: flex-start;

    @media (min-width: $screen-tablet-landscape) {
      width: auto;
      order: 1;
      margin-top: 0;
    }
  }

  &__footer-button-col {
    width: 100%;
    order: 1;
    margin-top: 20px;

    @media (min-width: $screen-tablet-landscape) {
      width: auto;
      order: 2;
      margin-top: 0;
    }

    > .button {
      width: 100%;
    }
  }
}
