.checkout-step-delivery {
  &__headline,
  &__sub-headline {
    font-size: $formHeadlineFontSizeMobile;
    font-family: $fontHeadlineFamily;
    margin: $formHeadlineMarginMobile;
    font-weight: $formHeadlineFontWheight;
    color: $formHeadlineColor;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $formHeadlineFontSizeDesktop;
      margin: $formHeadlineMarginDesktop;
    }
  }

  &__sub-headline {
    font-size: 18px;
    font-family: $fontPrimaryFamily;
    font-weight: $fontWeightRegular;
    line-height: 1.3;
    margin: 0 0 24px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 21px;
    }
  }

  &__subline {
    display: none;
  }

  &__section:nth-of-type(1) {
    margin-bottom: 24px;
  }

  .checkout-page__row {
    align-items: flex-start;
  }

  .checkout-step-next-button {
    margin-top: 16px;
    min-width: 200px;
  }

  .checkout__box {
    &:last-child {
      margin-bottom: 0;
      border: 0;
      padding-bottom: 0;
    }
  }

  .general-message {
    &__message {
      font-size: 14px;
    }
  }
}
