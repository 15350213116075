.checkout-page {
  $root: &;
  padding: 24px 0;
  background: $checkoutPageBackground;

  @media (min-width: $screen-tablet-portrait) {
    padding: 24px 0;
    margin-bottom: 72px;
  }

  @media (min-width: $screen-desktop-sm) {
    padding: 40px 0;
  }

  &__row {
    display: flex;
    width: 100%;
    gap: 24px;
    max-width: $maxWidthOuter;
    flex-wrap: wrap;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 24px;
      margin: 0 auto;
      flex-wrap: nowrap;
    }

    @media (min-width: $screen-tablet-landscape) {
      gap: 40px;
    }

    @media (min-width: $screen-desktop-sm) {
      padding: 0 40px;
    }
  }

  &__column {
    background: $sandHoverColor;
    padding: 24px 10px;
    width: 100%;

    @media (min-width: $screen-mobile-sm) {
      padding: 24px 20px;
    }

    @media (min-width: $screen-tablet-portrait) {
      border-radius: 3.8px;
      width: 50%;
    }

    @media (min-width: $screen-desktop-sm) {
      padding: 24px 28px;
    }

    &--tablet-auto {
      @media (min-width: $screen-tablet-portrait) {
        width: 100%;
      }

      @media (min-width: $screen-tablet-landscape) {
        width: 50%;
      }
    }

    &--one {
      @media (min-width: $screen-tablet-portrait) {
        width: auto;
        min-width: 50%;
      }

      @media (min-width: $screen-desktop) {
        max-width: 65%;
      }
    }

    &--sidebar {
      display: none;

      @media (min-width: $screen-tablet-portrait) {
        background: $whiteColor;
        display: block;
        padding: 20px;
      }
    }
  }

  .address-form {
    width: calc(100% - 8px);
    margin: 0 8px 0 0;

    @media (min-width: $screen-mobile-sm) {
      width: 100%;
      margin: 0;
    }

    .radio-input__row {
      @media (min-width: $screen-mobile-large) {
        margin-left: -8px;
        margin-right: 0;
      }
    }
  }

  &__mandatory-text {
    font-size: $fontSizeXXSmall;
    margin-top: 16px;

    &--m8 {
      margin-top: 8px;
    }
  }
}
