.delivery-forecast-message {
  display: flex;
  font-size: $deliveryForecastMessageFontSizeMobile;
  line-height: 1.4;

  @media (min-width: $screen-tablet-portrait) {
    font-size: $deliveryForecastMessageFontSizeDesktop;
  }

  &__icon {
    margin: 0 16px 0 0;
    float: left;
    align-self: center;

    & > i {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__text-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__text {
    display: inline;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
    }

    strong {
      color: $textSuccessColor;
    }
  }

  &__carrier {
    color: $grayDarkColor;
    display: inline;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
    }
  }

  &__extra-shipping {
    display: inline;

    @media (min-width: $screen-tablet-landscape) {
      padding: 0;
      display: inline-block;
    }
  }

  &__date-text,
  &__shipping-restriction {
    display: block;
  }

  &__date-text strong {
    font-weight: $fontWeightRegular;
  }
}

.sprite-forecast-dhl_swiss { // stylelint-disable-line
  @extend .sprite-forecast-dhl_eu;
}
