.greeting-card-form {
  width: 100%;
  font-size: 14px;
  line-height: 1.2;
  margin: 0 0 16px;

  @media (min-width: $screen-mobile-landscape-max) {
    max-width: 100%;
  }

  &__checkbox {
    padding: 0 0 8px;

    .checkbox__box {
      top: 0;

      @media (min-width: $screen-tablet-portrait) {
        top: 1px;
      }
    }

    .checkbox {
      &__label {
        font-size: 14px;
      }
    }
  }

  &__textarea .input__wrapper {
    min-height: 118px;
    width: 100%;
    padding: 8px;
    font-size: 12px;
    border: $giftGreetingTextBorder;
    border-radius: $giftGreetingTextBorderRadius;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 14px;
      padding: 8px 8px 14px 14px;
    }

    .input__field {
      font-size: 12px;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 14px;
      }
    }
  }

  &__counter {
    text-align: right;
    font-size: 10px;
    line-height: 1.2;
    padding: 0 0 2px;
  }
}
